.m-t{margin-top: 15px !important;}
.m-t-5{margin-top: 5px !important;}
.m-t-xs{margin-top: 5px !important;}
.m-t-lg{margin-top: 30px !important;}
.m-t-lg-2{margin-top: 40px !important;}
.m-t-lg-3{margin-top: 56px !important;}
.m-t-24{margin-top: 24px !important;}
.m-r{margin-right: 15px !important;}
.m-b{margin-bottom: 15px !important;}
.m-b-lg{margin-bottom: 30px !important;}
.m-b-7{margin-bottom: 7px !important;}
.m-b-xxl{margin-bottom: 105px !important;}
.m-l{margin-left: 15px !important;}
.m-r-0{margin-right: 0px !important;}
.m-b-0{margin-bottom: 0px !important;}
.m-r-xs{margin-right: 5px !important;}
.m-b-xs{margin-bottom: 5px !important;}
.m-r-lg{margin-right: 30px !important;}
.m-l-xs{margin-left: 5px !important;}
.m-l-lg{margin-left: 30px !important;}
.m-l-24{margin-left: 24px !important;}
.m-l-va{margin-left: 80% !important;}
.h-a{height: auto !important;}
.m-l-0{margin-left: 0px !important;}
.m-t-huge {
    margin-top: 134px !important;
}
.p-t{padding-top: 15px !important;}
.p-t-xs{padding-top: 5px !important;}
.p-t-lg{padding-top: 30px !important;}
.p-t-10{padding-top: 10px !important;}
.p-r{padding-right: 15px !important;}
.p-r-0{padding-right: 0px !important;}
.p-r-24{padding-right: 24px !important;}
.p-b{padding-bottom: 15px !important;}
.p-b-lg{padding-bottom: 30px !important;}
.p-b-xlg{padding-bottom: 60px !important;}
.p-b-120{padding-bottom: 120px !important;}
.p-b-190{padding-bottom: 190px !important;}
.p-b-3{padding-bottom: 3px !important;}
.p-l{padding-left: 15px !important;}
.p-l-0{padding-left:0px !important;}
.p-l-24{padding-left:24px !important;}


.nowrap {
	white-space: nowrap !important;
}

.text-left {
	text-align: left !important;
}

.text-right {
	text-align: right !important;
}

.text-center {
	text-align: center !important;
}

.vertical-center {
	vertical-align: middle !important;
}


.align-center {
	text-align: center !important;
}