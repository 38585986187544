@import 'helpers.scss';
@import 'variables.scss';

$theme-colors: (
  "primary": #ED1C24,
  "secondary": #565656,
  "third": #333333,
  "fourth": #F6F6F6,

);



@import '~bootstrap/scss/bootstrap.scss';

