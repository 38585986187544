@import "variables.scss";
@import "react-redux-toastr/src/styles/index.scss";

.vidaTres .page-item.active .page-link {
  background-color: $primary-vidatres !important;
}

.vidaTres .page-link:hover {
  color: $primary-vidatres !important;
}

.vidaTres .page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(86, 167, 105);
}

.vidaTres li.active.page-item .page-link {
  color: #fff !important;
  border-color: $primary-vidatres !important;
}

.vidaTres li.page-item .page-link {
  color: $primary-vidatres !important;
  border-color: $primary-vidatres !important;
}

.btn-primary {
  &:disabled {
    background-color: #f6f6f6;
    border: solid 1px #cccccc;
    color: #b8b8b8;
  }
}

.btn-link {
  text-decoration: underline;
  color: #565656;
}

.table {
  font-size: 11px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: $third-banmedica;
}

.calculoInteres-big {
  width: 460px;
}

.banmedica table {
  th {
    background-color: $primary-banmedica;
    color: #ffffff;
    padding: 10px;
  }
}

.vidaTres table {
  th {
    background-color: $primary-vidatres;
    color: #ffffff;
    padding: 10px;
  }
}

.card-header {
  font-weight: bold;
}

.form-label {
  font-weight: bold;
  font-size: 0.6rem;
}

.starter-template {
  padding: 3rem 1.5rem;
  text-align: center;
}

.container-margin {
  margin-top: 50px;
}

.font-size-normal {
  font-size: 0.7rem;
}

h2 {
  color: #333;
  font-size: 25px;
  font-weight: bold;
  display: block;
}

body {
  width: 714px;
  font-family: Segoe UI, Verdana, Geneva, sans-serif;
  font-size: 11px;
  color: $third-banmedica;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
}

* {
  margin: 0;
  padding: 0;
  list-style: none;
  border: none;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a:focus {
  outline: none;
}

.clear {
  clear: both;
}

.contenedor.banmedica {
  margin: 0 auto;
  padding: 0 0 10px 0;
  font-size: 11px !important;
}

.contenedor.vidaTres {
  margin: 0 auto;
  padding: 0 0 10px 0;
  font-size: 11px !important;
}

.vertical-center {
  vertical-align: middle !important;
}

.align-center {
  text-align: center !important;
}

.banmedica .titulosTabla {
  background: #cf020d;
  color: #fff;
  text-align: left;
  font-weight: normal;
  vertical-align: center !important;
  width: 120px;
}

.vidaTres .titulosTabla {
  background: #62aa8a;
  color: #fff;
  text-align: left;
  font-weight: normal;
  vertical-align: center !important;
  width: 120px;
}

.banmedica .titulosTabla-horizontal {
  background: #cf020d;
  color: #fff;
  text-align: left;
  font-weight: normal;
  vertical-align: center !important;
}

.vidaTres .titulosTabla-horizontal {
  background: #62aa8a;
  color: #fff;
  text-align: left;
  font-weight: normal;
  vertical-align: center !important;
}

.titulosTabla-calculoInteres {
  color: black;
  font-size: 12px !important;
  text-align: left;
  vertical-align: center !important;
}

.valores-calculoInteres {
  font-size: 12px !important;
  font-weight: bold;
}

.total-calculoInteres {
  color: black;
  font-size: 12px !important;
  text-align: left;
  font-weight: bold;
  vertical-align: center !important;
  padding-top: 15px !important;
  border-top: 4px #dee2e6 solid !important;
}

.h2 {
  color: #4d5d73;
  font-size: 14px;
  font-weight: bold;
}

.txt-bajada {
  font-size: 15px;
  color: #707070;
}

input {
  border-radius: 6px !important;
}

.vidaTres input:focus {
  border-radius: 6px !important;
  border-color: $primary-vidatres !important;
  box-shadow: 0 0 0 0.2rem rgba(86, 167, 105, 0.3);
}

.vidaTres select:focus {
  border-radius: 6px !important;
  border-color: $primary-vidatres !important;
  box-shadow: 0 0 0 0.2rem rgba(86, 167, 105, 0.3);
}

.modal-header {
  .modal-title {
    font-size: 16px;
  }

  background: #f6f6f6;
}

.iconPointer:first-child {
  cursor: pointer;
  margin-right: 10px !important;
}

.iconPointer {
  cursor: pointer;
}

.input-group-text {
  background-color: transparent;
  border-right: none;
  color: #ccc;
  border-radius: 6px 0px 0px 6px !important;
}

.input-addon input {
  border-radius: 0px 6px 6px 0px !important;
}

.alinear-boton {
  text-align: center;
  padding-top: 50px;
}

.info-feedback {
  margin-top: 5px;
  line-height: 16px;
}

.select-especial {
  padding: 0px 5px;
}

.contenedor.banmedica {
  margin: 0 auto;
  padding: 0 0 10px 0;
}

.displayError {
  display: block !important;
  position: absolute;
  right: 10px;
  bottom: 8px;
  font-size: 10px;
  font-weight: bold;
  z-index: 10;
}

.displayErrorInside {
  display: block !important;
  position: absolute;
  right: 10px;
  bottom: 11px;
  font-size: 10px;
  font-weight: bold;
  z-index: 10;
}

.invalid-feedback {
  width: auto;
}

#contenedor2 {
  margin: 0 0 0 40px;
  padding: 0 0 10px 0;
}

.hr2 {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 3px solid rgba(0, 0, 0, 0.71);
}

.inputCalendar {
  display: block !important;
  width: 100% !important;
  height: calc(1.5em + 0.75rem + 2px) !important;
  padding: 0.375rem 0.75rem !important;
  font-size: 0.8rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: #495057;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
  -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.custom-control-label {
  padding-top: 5px;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block !important;
}

.titulos-horizontal-fix-1 {
  width: 129px;
}

/*** PAGO DE COTIZACIONES ****/

.tableDeudaPago {
  border: 2px solid #000;
  border-collapse: collapse;
  border-spacing: 0;
  width: 684px !important;
  font-size: 9px !important;
  background: #fff;
}

.banmedica .tableDeudaPago thead th {
  background: #cf020d;
  color: #fff;
  text-align: left;
  border: solid 1px #000;
  height: 32px;
  font-weight: normal;
}

.vidaTres .tableDeudaPago thead th {
  background: #62aa8a;
  color: #fff;
  text-align: left;
  border: solid 1px #000;
  height: 32px;
  font-weight: normal;
}

.custom-control-label:before {
  background-color: white;
}

.vidaTres .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: $primary-vidatres;
  border-color: $primary-vidatres;
}

.tableDeudaPago td {
  border: 1px solid #000;
}

.tableDeudaPago th {
  border: 1px solid #000;
}

.tableDeudaPago tr td {
  border-right: 0;
  padding: 0.2rem !important;
}

.tableDeudaPago tr:last-child td {
  border-bottom: 0;
}

.tableDeudaPago tr td:first-child,
.tableDeudaPago tr th:first-child {
  border-left: 0;
}

.tableDeudaPago tr td {
  border-top: 0;
}

.tableDeudaPago .total {
  font-weight: bold;
}

.mainDetallePago {
  border-left: 2px solid #6b6d70;
  border-right: 2px solid #6b6d70;
  border-bottom: 1px solid #6b6d70;
  border-top: 1px solid #6b6d70;
}

.deudaPagoPeriodoTitulos {
  background: #909396;
  border-top: 0px !important;
  border-left: 1px solid #6b6d70 !important;
  border-right: 1px solid #6b6d70 !important;
  border-bottom: 1px solid #6b6d70 !important;
  text-align: center;
  font-size: 11px;
  color: #000;
}

.banmedica .deudaPagoTitulos {
  background: #cf020d;
  color: #fff;
  border-top: 0px !important;
  border-left: 1px solid #6b6d70 !important;
  border-right: 1px solid #6b6d70 !important;
  border-bottom: 0px !important;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
}

.vidaTres .deudaPagoTitulos {
  background: #62aa8a;
  color: #fff;
  border-top: 0px !important;
  border-left: 1px solid #6b6d70 !important;
  border-right: 1px solid #6b6d70 !important;
  border-bottom: 0px !important;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
}

.deudaPagoTitulosTotal {
  background: #909396;
  color: #fff;
  border-top: 0px !important;
  border-left: 1px solid #6b6d70 !important;
  border-right: 1px solid #6b6d70 !important;
  border-bottom: 0px !important;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
}

.botonExpandir {
  border: 0px !important;
  background-color: #909396 !important;
}

.campoEditable {
  padding: 0px !important;
  height: 25px !important;
  font-size: 12px !important;
  height: fit-content;
  margin-bottom: 0px !important;
}

.banmedica .paddingColor {
  padding-right: 15px !important;
  background: #cf020d;
}

.vidaTres .paddingColor {
  padding-right: 15px !important;
  background: #62aa8a;
}

.addScroll {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 400px;
}

.inputRequired {
  border: 1px solid red !important;
}

.lista {
  list-style-type: circle !important;
  list-style: circle !important;
}

.banmedica .tableEmpleador {
  border: 1px solid #cf020d;
  border-collapse: separate;
  border-spacing: 0;
}

.vidaTres .tableEmpleador {
  border: 1px solid #62aa8a;
  border-collapse: separate;
  border-spacing: 0;
}

.banmedica .tableEmpleador td {
  border: 1px solid #000;
}

.banmedica .tableEmpleador th {
  border: 1px solid #000;
}

.banmedica .tableEmpleador tr td {
  border-right: 0;
}

.banmedica .tableEmpleador tr:last-child td {
  border-bottom: 0;
}

.banmedica .tableEmpleador tr td:first-child,
.banmedica .tableEmpleador tr th:first-child {
  border-left: 0;
}

.banmedica .tableEmpleador tr td {
  border-top: 0;
}

.vidaTres .tableEmpleador td {
  border: 1px solid #000;
}

.vidaTres .tableEmpleador th {
  border: 1px solid #000;
}

.vidaTres .tableEmpleador tr td {
  border-right: 0;
}

.vidaTres .tableEmpleador tr:last-child td {
  border-bottom: 0;
}

.vidaTres .tableEmpleador tr td:first-child,
.vidaTres .tableEmpleador tr th:first-child {
  border-left: 0;
}

.vidaTres .tableEmpleador tr td {
  border-top: 0;
}

.tableEditando-chica {
  font-size: 12px !important;
  height: fit-content;
  padding: 0.2rem !important;
  margin-bottom: 0px !important;
}

//Deuda Empresa
.react-bootstrap-table table {
  overflow-x: scroll !important;
}
.form-periodo-banmedica {
  width: 80%;
  border: 2px solid #cf020d;
  padding: 0 !important;
  margin: 0 !important;
  margin-bottom: 15px !important;

  .label-periodo-banmedica {
    padding: 10px;
    background: #cf020d;
    color: #fff;
    font-weight: bolder;
  }
  button {
    margin: 5px 10px;
  }
}
.form-periodo-banmedica-td {
  padding: 5px;
  background: #cf020d;
  color: #fff;
  font-weight: bolder;
}
.form-periodo-banmedica-td-res {
  padding: 5px;
  width: 130px;
  min-width: 130px;
  max-width: 130px;
}

.form-periodo-tres {
  width: 80%;
  border: 2px solid #62aa8a;
  padding: 0 !important;
  margin: 0 !important;
  margin-bottom: 15px !important;
  div.react-bootstrap-table table.table.table-striped.table-hover {
    overflow-x: scroll !important;
  }
  .label-periodo-tres {
    padding: 10px;
    background: #62aa8a;
    color: #fff;
    font-weight: bolder;
  }
  button {
    margin: 5px 10px;
  }
}

.form-periodo-tres-td {
  padding: 5px;
  background: #62aa8a;
  color: #fff;
  font-weight: bolder;
}
.form-periodo-tres-td-res {
  padding: 5px;
  width: 130px;
  min-width: 130px;
  max-width: 130px;
}

.row.react-bootstrap-table-pagination {
  @media (min-width: 576px) {
    .col-sm-6 {
      flex: 0 0 50%;
      max-width: 50%;
      margin-right: -65px;
    }
  }
}
