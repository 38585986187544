$btn-border-radius: 1.1rem;
$btn-border-radius-lg: 1.1rem;
$btn-border-radius-sm: 1.1rem;
$input-btn-padding-x: 1.4rem;
$input-btn-padding-x-sm: 1.4rem;
$input-btn-padding-x-lg: 1.4rem;
$primary-banmedica: #ED1C24;
$secondary-banmedica: #565656;
$third-banmedica: #333333;
$fourth-banmedica: #F6F6F6;
$primary-vidatres: #009B67;
$secondary-vidatres: #3B4d72;
